<template>
    <div>
        <ProtoNavigationBar
            theme="accadisHSTheme"
            image="/img/icon-restaurant.png"
        >
            <template #title>
                accadis <span class="font-weight-bold">Infoanzeige</span>
            </template>
        </ProtoNavigationBar>

        <v-container
            fluid
            class="pa-0"
        >
            <v-row>
                <v-col class="pa-0">
                    <!-- START HEADER -->

                    <v-container
                        id="hero"
                        class="pa-0 mt-4"
                    >
                        <v-card class="">
                            <div
                                class="text-center text-h3 pb-2 pt-10 font-weight-bold"
                                style="color: #7d7d7d"
                            >
                                Screen Übersicht
                            </div>
                        </v-card>
                    </v-container>

                    <!-- END HEADER -->

                    <v-container class="ma-2 pl-10">
                        <v-row>
                            <v-col
                                cols="6"
                                class="ma-3"
                            >
                                <p>
                                    Die Anzeigen sind für eine Bildschirmauflösung von 1920 × 1080 Optimiert. Bitte VOR dem Aufrufen einer Anzeige auf die Auflösung von 1920 × 1080 wechseln.<br>
                                    Ein Nachjustieren der Auflösung, bei bereits geöffneter Anzeige, kann zu fehlerhaften Anzeigen führen.
                                </p>
                                <br>
                                <p>
                                    Die Konfiguration der Screens kann im <a
                                        href="https://contenthub.accadis-bildung.de/admin/content/Infoanzeige"
                                        target="blank"
                                    >Content HUB</a> vorgenommen werden.
                                </p>
                                <br>
                                <h2>Screens</h2>
                                <v-list
                                    :loading="isLoading"
                                    lines="one"
                                    density="compact"
                                >
                                    <v-list-item
                                        v-for="item in screens"
                                        :key="item.id"
                                    >
                                        <nuxt-link
                                            :to="`/dynamic/${item.id}`"
                                        >
                                            {{ item.id }}
                                        </nuxt-link>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col
                                cols="3"
                                class="ma-3"
                            >
                                <login-component />
                                <h2>Anzeigen</h2>
                                <v-list
                                    :loading="isLoading"
                                    lines="one"
                                    density="compact"
                                >
                                    <v-list-item
                                        v-for="item in acrchived"
                                        :key="item.id"
                                    >
                                        <nuxt-link
                                            :to="`/dynamic/${item.id}`"
                                        >
                                            {{ item.id }}
                                        </nuxt-link>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup lang="ts">
const configUrl = '/api/v1/info-screen-config';
const { data, status, error } = useFetch(configUrl);
if (error.value) throw createError(error.value);

const screens = computed(() =>
    data.value?.data
        .filter(item => item.status != 'archived'),
);

const acrchived = computed(() =>
    data.value?.data
        .filter(item => item.status === 'archived'),
);

const isLoading = computed(() => status.value === 'pending');
</script>

<style lang="css">

</style>
